#root {
  height: 100vh;
  width: 100%;
}

.ant-message {
  z-index: 999999 !important;
  .ant-message-custom-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.btn-upload {
  color: #fd397a !important;
  border: 1px solid !important;
  padding: 10px 15px !important;
  height: auto !important;

  svg {
    margin-right: 10px;
    vertical-align: bottom;
  }
}

.colors {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 40px;

  div {
    width: 45%;
  }

  span {
    display: block;
    margin-bottom: 20px;
  }

  button.color {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 10px;
    font-size: 0px;
    border-radius: 100%;
    border: none;
    box-shadow: 0px 0px 2px rgba(59, 71, 80, 0.1),
      0px 3px 5px rgba(59, 71, 80, 0.1);
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.image-box {
  background: #eee;
  width: 120px;
  height: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  border: 1px dashed #fd397a;
  color: #fd397a;
  justify-content: center;
}

.action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.mobile-logo-header {
  width: 100%;
  padding: 0.7rem 1rem;
  background-color: #232323;
  border-bottom: 1px solid #d5d5d5;
  box-shadow: 0px 3px 4px #c5c5c5;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 11;
  @media (min-width: 1024px) {
    display: none;
  }

  .title {
    &.only-desk {
      display: none;
      @media (min-width: 1024px) {
        display: flex;
      }
    }
  }

  .mobile-logo-header {
    border-bottom: none;
    box-shadow: none;
    padding: 0;
  }

  img.app-logo {
    height: 1.6rem;
  }

  #connect-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    color: #fff;
    font-size: 0.85rem;
    @media (min-width: 1024px) {
      color: #000;
    }
    svg {
      width: 1.2rem;
      font-size: 1.2rem;
      margin-right: 0.5rem;
      @media (min-width: 1024px) {
        color: #ff5c92;
      }
    }
  }
}

nav.nav {
  width: 100%;
  max-width: 430px;
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-size: 0.8rem;
  margin: 0 auto;
  justify-content: space-between;
  padding: 3rem 0.5rem 0;
  @media (min-width: 375px) {
    padding: 3rem 0.7rem 0;
    font-size: 0.9rem;
  }
  @media (min-width: 400px) {
    padding: 3rem 1rem 0;
  }
  @media (min-width: 1024px) {
    width: auto;
    max-width: unset;
    margin: 0rem 0 0rem 4.8rem;
    padding: 0;
    justify-content: initial;
  }

  .plus-button {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  a {
    margin-right: 10px;
    display: flex;
    @media (min-width: 375px) {
      margin-right: 15px;
    }
    @media (min-width: 400px) {
      margin-right: 20px;
    }
    @media (min-width: 1024px) {
      margin-right: 30px;
    }
    color: #000;

    i {
      margin-right: 5px;
      vertical-align: bottom;

      svg {
        width: 13px;
        path {
          stroke: #232323;
        }
        @media (min-width: 375px) {
          width: 16px;
        }
        @media (min-width: 1024px) {
          width: 16px;
        }
      }
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    span {
      &.span-menu-desk {
        display: none;
        @media (min-width: 1024px) {
          display: block;
        }
      }
      &.span-menu-mob {
        display: block;
        @media (min-width: 1024px) {
          display: none;
        }
      }
    }
  }

  &.store {
    a.active {
      color: #ffb822;
      border-bottom: 3px solid;
      font-weight: 500;
      i {
        svg {
          path {
            stroke: #ffb822;
          }
        }
      }
    }
  }

  &.connect {
    a.active {
      color: #fd397a;
      border-bottom: 3px solid;
      font-weight: 500;
    }
  }
}

.ant-input-affix-wrapper {
  .ant-input-suffix {
    margin-left: 4px;
    margin-right: 20px;
  }
}
.ant-input-affix-wrapper > input.ant-input {
  background: none !important;

  &::placeholder {
    color: #000 !important;
    transition: all 0.3s ease;
  }

  &:focus {
    box-shadow: none !important;
    &::placeholder {
      color: #000 !important;
      opacity: 0.4;
    }
  }
}
.tox.tox-tinymce {
  width: 100%;
  border: 1px solid #afafaf;
  border-radius: 10px;
}

.switch-wrapper {
  width: 100%;
  padding: 0.37rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    padding: 0;
  }

  .ant-switch {
    min-width: 30px;
    height: 15px;
    line-height: 15px;
  }
  .ant-switch-checked {
    background: #0df0173b;
    .ant-switch-handle {
      left: calc(100% - 15px - 2px);
      &::before {
        background-color: #0df017;
      }
    }
  }
  .ant-switch-handle {
    width: 18px;
    height: 18px;
    top: -2px;
    left: 0;
    &::before {
      border-radius: 50%;
      background-color: #888;
    }
  }
  .anticon {
    vertical-align: 0;
  }
}

.primary-button {
  &.store {
    width: 180px;
    height: 36px;
    background: #ffa800;
    border: 0;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 19px;
    color: #f5f5f5;
    box-shadow: 3px 3px 5px #d5d5d5;
    transition: all 0.3s ease;
  }
  &:active {
    box-shadow: 0 0 4px #d5d5d5;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
