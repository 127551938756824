.connect-steps {
  padding: 40px 150px 0 0;

  h1 {
    font-size: 20px;
    margin-bottom: 40px;
    font-weight: 500;
  }

  h2 {
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 40px;
  }

  .btn-add {
    cursor: pointer;
    color: #fd397a;
    border: 1px solid;
    border-radius: 5px;
    padding: 10px 0;
    height: auto;
    margin-bottom: 30px;

    &:hover,
    &:focus {
      background-color: #fd397a;
      color: #fff;
      border-color: #fd397a;
    }
  }

  form {
    .ant-input {
      border: none;
      font-style: italic;
      padding: 0;
    }

    .ant-col.ant-form-item-label {
      padding-bottom: 0;

      label {
        font-size: 12px !important;
      }
    }

    .ant-form-item {
      margin-bottom: 10px;
    }

    div[role='alert'] {
      font-size: 12px;
      margin-top: 2px;
    }

    .btn-submit {
      width: 180px;
      height: 36px;
      background: #fd397a;
      border: 0;
      border-radius: 4px;
      font-style: normal;
      font-size: 0.8rem;
      color: #f5f5f5;
      float: right;
    }

    ul {
      list-style: none;

      .link-group {
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        margin-bottom: 35px;

        .anticon[tabindex] {
          position: absolute;
          top: 45px;
          right: -30px;
          font-size: 18px;
          color: #fd397a;
        }
      }
    }
  }

  .link-card {
    padding: 20px;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0px 0px 1px rgba(59, 71, 80, 0.03),
      0px 8px 16px rgba(59, 71, 80, 0.06);
    border-radius: 10px;
  }
}
