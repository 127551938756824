.suiteshare-table {
  width: 100%;
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
  .ant-empty-description {
    font-size: 1rem;
    color: #3f3f3f;
    margin: 2rem 0rem;
  }
  .ant-empty-image {
    height: 180px;
    margin: 20px;
    position: relative;
  }
  .ant-pagination {
    padding: 0 16px;
  }
  .ant-empty {
    margin: 0 8px 20px;
  }

  .lazy-load-image-background {
    height: 100%;
  }

  &.mobile {
    display: block;
    @media (min-width: 1024px) {
      display: none;
    }
  }
  .table-picture {
    width: 50px;
    height: 50px;
    margin: 0;
    img,
    .no-img {
      width: 100%;
      height: 100%;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      object-fit: contain;
    }
    .no-img {
      background-color: #e5e5e5;
      color: #a5a5a5;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.empty-table-mobile {
  @media (min-width: 1024px) {
    display: none;
  }
  .ant-empty {
    .ant-empty-image {
      height: 220px !important;
      margin: 20px;
      position: relative;
      left: -0.9rem;
      @media (min-width: 1024px) {
        height: 320px !important;
      }
    }
  }
}

.mobile-list-filter {
  display: block;
  @media (min-width: 1024px) {
    display: none;
  }
}

.suiteshare-card {
  &.mobile {
    @media (min-width: 1024px) {
      display: none;
    }
  }
  &.category {
    width: 100%;
    max-width: 400px;
    margin-top: 16px;
    .ant-card-cover {
      img {
        max-height: 110px;
        object-fit: cover;
      }
    }
    .ant-card-body {
      padding: 1rem;
    }
  }
}

.ant-popover {
  .ant-popover-message {
    max-width: 200px;
    font-size: 0.8rem;
    text-align: justify;
  }
}
