.suiteshare-descriptions {
  width: 100%;
  margin: 0;
  @media (min-width: 1024px) {
    width: calc(100% + 60px);
    margin-left: -30px;
  }
  .ant-descriptions-item-label {
    padding-bottom: 10px;
  }
  .ant-descriptions-item {
    padding: 0;
    @media (min-width: 1024px) {
      padding: 0 15px;
    }
  }

  .ant-descriptions-item-content {
    margin-bottom: 20px;
  }

  input,
  .ant-select {
    width: 100%;
    border-radius: 0;
  }

  .ant-select {
    .ant-select-selector {
      border: none !important;
      border-bottom: 1px solid #a1a1a1 !important;
    }
  }

  textarea {
    resize: none;
    min-height: 8em;
    padding-bottom: 5px;
    border-radius: 0;
  }
}
