@import '../../../../../scss/keyframes';

.sellers-list {
  width: 100%;
  margin: 2rem 0 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: fade-in 0.5s;
  @media (min-width: 1024px) {
    margin: 3rem 0 0 0;
  }

  .sellers-list__action-bar {
    width: 100%;
    max-width: 400px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: center;
    @media (min-width: 1024px) {
      max-width: unset;
      align-items: center;
      padding: 0 0 0 0.3rem;
    }

    .action-bar__text-wrapper {
      font-family: 'Poppins', sans-serif;
      text-align: left;
      gap: 1rem;
      h1 {
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: 0;
        @media (min-width: 1024px) {
          font-size: 1.3rem;
        }
      }
      p {
        font-size: 0.8rem;
        font-weight: 400;
        margin-bottom: 0;
        @media (min-width: 1024px) {
          margin-bottom: 1rem;
        }
        span {
          font-family: 'Lato', sans-serif;
          font-size: 0.9rem;
        }
      }
    }
    .action-bar__button-wrapper {
      width: 33%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        border: none;
        border-radius: 0.5rem;
        color: #fff;
        font-weight: 500;
        padding: 0.7rem 1.5rem;
        &.button--ask-for-more {
          background-color: #25d366;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 0.5rem;
          svg {
            height: 1rem;
            width: auto;
          }
        }
        &.button--call-register {
          background: #ffa800;
        }
      }
    }
  }

  .sellers-list__main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0 0;
    @media (min-width: 1024px) {
      min-height: 10rem;
    }

    .main-content__search-input {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 2rem;

      .search-input__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #c8c8c8;
        padding: 0.3rem 0.6rem;
        border-radius: 0.2rem;
        input {
          border: none;
        }
      }
    }

    .sellers-cards-list {
      gap: 2.5rem 1rem;
      align-items: stretch;
      justify-content: flex-start;
    }
  }
}
